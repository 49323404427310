.lang__vector-group {
  display: flex;
  gap: 7px;

  max-width: 50px;
  padding: 3px;
  cursor: pointer;
}
.group {
  position: relative;
}
.vectorup {
  display: block;
  transform: rotate(180deg);
  transition: 0.3s;
  margin-top: 5px;
  scale: 1.1;
}
.none {
  display: block;
  transition: 0.3s;
  margin-top: 5px;
  scale: 1.1;
}
.change-lang {
  border: 1px solid #03a9f4;
  padding: 3px 0px;
  position: absolute;
  top: 35px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
  z-index: 100;
}
.change-lang-unvisible {
  visibility: hidden;
  border: 1px solid white;
  padding: 5px 0px;
  position: absolute;
  top: 35px;
  right: 0px;
  background-color: #ffffff;
  border-radius: 5px;
  cursor: pointer;
}
.change-lang-span {
  width: 100%;
  display: inline-block;
  background-color: #ffffff;
  color: #000000;
  padding: 5px 30px;
  color: #03a9f4;
}
.change-lang-span:nth-child(1):hover {
  background-color: #03a9f4;
  color: #000000;
}
.change-lang-span:nth-child(2):hover {
  background-color: #03a9f4;
  color: #000000;
}

.hidden {
  display: none;
}
.back-drop {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
}
@media (max-width: 300px) {
  .lang__vector-group {
    display: flex;
    gap: 2px;
    font-size: 13px;
    max-width: 40px;
    padding: 3px;
    cursor: pointer;
  }
  .group {
    top: 15px;
  }
}
