.container-main-service {
  padding-top: 45px;
}

.main-title_uslugi {
  margin: 10px auto 15px auto;
  padding: 50px;
  opacity: 0.9;
  border-radius: 20px;
  width: 100%;
  text-align: center;
}
.main-title_uslugi p {
  font-size: 30px;
  font-weight: 700;
  padding-bottom: 20px;
}
.main-title_uslugi-line {
  max-width: 150px;
  height: 3px;
  background-color: grey;
  opacity: 0.6;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 20px;
}
.main-title_uslugi h1 {
  font-size: 28px;
  font-weight: 500;
  color: black;
  font-family: Georgia, serif;
}

.main__services-all {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin-bottom: 20px;
}
.main__services-all-item {
  position: relative;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease;
}
.main__services-all-item:hover .main-services-link {
  animation-name: main-service-link;
  animation-duration: 0.2s;
  animation-timing-function: linear;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
  transition: all 0.3s ease;
}

.main__services-all-item:hover .main__services-all-img {
  object-fit: cover;
  transition: all 0.3s ease;
}
.main__services-all-item:hover .main__services-all-title {
  animation-name: main-service-title;
  animation-duration: 0.2s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: forwards;
}
.main__services-all-img {
  object-fit: fill;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  transition: all 0.3s ease;
}
.main__services-all-img:hover {
  opacity: 0.3;
}
.main__services-all-text {
  position: absolute;
  top: 50%;
  left: 0;
  z-index: 2;
  color: #ffffff;
  width: 100%;
  text-align: center;
}
.main-services-link {
  display: inline-block;
  padding: 15px 30px;
  border: 1px solid rgb(22, 164, 235);
  border-radius: 5px;
  opacity: 0;
}
.main-services-link:hover {
  background-color: #03a9f4;
}
@keyframes main-service-link {
  from {
    opacity: 0;
    margin-top: 40px;
  }
  50% {
    opacity: 0.5;
    margin-top: 38px;
  }
  to {
    opacity: 1;
    margin-top: 35px;
  }
}
@keyframes main-service-title {
  from {
    margin-top: 0;
  }
  50% {
    margin-top: -35px;
  }
  to {
    margin-top: -45px;
  }
}
@media (max-width: 1100px) {
  .main__services-all {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  .main__services-all-title {
    font-size: 18px;
  }
}
@media (max-width: 800px) {
  .main__services-all {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  .main__services-all-title {
    font-size: 15px;
  }
}
@media (max-width: 700px) {
  .main__services-all {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }
  .main__services-all-title {
    font-size: 18px;
  }
}
@media (max-width: 600px) {
  .main-title_uslugi {
    font-size: 20px;
    max-width: 500px;
  }
  .main-camera-img {
    scale: 0.7;
    position: relative;
    top: 15px;
    right: 0px;
  }
  .main-camera-img-rigth {
    scale: 0.5;
    position: relative;
    top: 15px;
    right: 0px;
  }
  .main-title_uslugi h1 {
    font-size: 24px;
  }
  .main__services {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
    margin-bottom: 20px;
  }
  .main__services-item h2 {
    font-size: 20px;
  }
}
@media (max-width: 550px) {
  .main__services-all {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
    margin-bottom: 20px;
  }

  .main__services-all-title {
    font-size: 22px;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  .main-title_uslugi h1 {
    font-size: 20px;
  }
  .main-services-link {
    display: inline-block;
    padding: 15px 30px;
    border: 2px solid rgb(22, 164, 235);
    border-radius: 5px;
    opacity: 1;
  }
  .main__services-all-item:hover .main-services-link {
    animation-name: none;
  }
  .main__services-all-item:hover .main__services-all-title {
    animation-name: none;
  }
}
@media (max-width: 500px) {
  .main-title_uslugi h1 {
    font-size: 20px;
  }
}

@media (max-width: 400px) {
  .main-title_uslugi {
    padding: 20px;
  }
  .main-title_uslugi p {
    font-size: 18px;
  }
  .main-camera-img {
    scale: 0.5;
    position: relative;
    top: 20px;
    right: 0px;
  }
  .main-title_uslugi h1 {
    font-size: 18px;
    margin: 0 auto;
  }

  .main-title h3 {
    font-size: 16px;
  }
  .camera-closed {
    display: none;
  }
  .main-drone-img {
    scale: 0.6;
    position: relative;
    top: 20px;
    left: 5px;
  }
}
@media (max-width: 350px) {
  .main__services-all-title {
    font-size: 16px;
    font-weight: 600;
    margin-top: -50px;
    margin-bottom: 50px;
  }
  .main-services-link {
    display: inline-block;
    padding: 7px 20px;
    border: 1px solid rgb(22, 164, 235);
    border-radius: 5px;
    opacity: 1;
  }
}
