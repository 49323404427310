.question {
  margin: 0 auto;
  padding-bottom: 30px;
  padding-top: 20px;
}
.question__title {
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  margin: 40px 0;
}
.question__text {
  display: flex;
  background-color: #f6f6f6;
  cursor: pointer;
  padding: 15px 30px;
}
.question__text span {
  padding-right: 15px;
  font-size: 18px;
  font-weight: 600;
}
.question__icon {
  width: 30px;
  margin-left: auto;
  color: blue;
  transition: all 0.3s;
}
.question__icon-none {
  width: 30px;
  margin-left: auto;
  color: blue;
  transform: rotate(180deg);
  transition: all 0.3s;
}
.question__item {
  margin-bottom: 20px;
}
.response-small {
  overflow: scroll;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #f6f6f6;
  animation-name: response-small;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.response-none-small {
  font-size: 0;
  animation-name: response-none-small;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  height: auto;
}
.response {
  overflow: scroll;
  font-size: 14px;
  padding: 20px;
  border: 1px solid #f6f6f6;
  animation-name: response;
  animation-duration: 0.5s;
  animation-timing-function: ease;
  animation-fill-mode: forwards;
}
.response-none {
  font-size: 0;
  animation-name: response-none;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  height: auto;
}
@keyframes response-small {
  from {
    opacity: 0;
    height: 0;
  }
  25% {
    opacity: 0.3;
    height: 25px;
  }
  25% {
    opacity: 0.5;
    height: 50px;
  }
  25% {
    opacity: 0.7;
    height: 75px;
  }
  to {
    opacity: 1;
    height: 120px;
  }
}
@keyframes response-none-small {
  from {
    opacity: 1;
    height: 120px;
  }
  25% {
    opacity: 0.7;
    height: 80px;
  }
  25% {
    opacity: 0.5;
    height: 60px;
  }
  25% {
    opacity: 0.3;
    height: 40px;
  }
  to {
    opacity: 1;
    height: 0px;
  }
}
@keyframes response {
  from {
    opacity: 0;
    height: 0;
  }
  25% {
    opacity: 0.3;
    height: 50px;
  }
  25% {
    opacity: 0.5;
    height: 100px;
  }
  25% {
    opacity: 0.7;
    height: 150px;
  }
  to {
    opacity: 1;
    height: 220px;
  }
}
@keyframes response-none {
  from {
    opacity: 1;
    height: 220px;
  }
  25% {
    opacity: 0.7;
    height: 150px;
  }
  25% {
    opacity: 0.5;
    height: 100px;
  }
  25% {
    opacity: 0.3;
    height: 50px;
  }
  to {
    opacity: 1;
    height: 0px;
  }
}
@media (max-width: 500px) {
  .question__title {
    font-size: 20px;
  }
  .question__text span {
    padding-right: 5px;
    font-size: 14px;
    font-weight: 600;
  }
}
@media (max-width: 400px) {
  .question__title {
    font-size: 20px;
  }
  .question__text span {
    padding-right: 5px;
    font-size: 12px;
    font-weight: 600;
  }
}
