.header_phone_link {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  color: #fff;
}
.header_phone_p {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
  margin-left: 3px;
}
.header_phone_p span:nth-child(3):hover {
  color: #03a9f4;
  text-decoration: underline;
}

.phone__logo {
  font-size: 13px;
  fill: #03a9f4;
  line-height: 1.2;
}

/* >>>>>>>>>>> */
.price-phone {
  padding-right: 5px;
  color: black;
}
.price-phone:hover {
  color: #979090;
  background-color: 'red';
}
.phone-contact {
  font-weight: 600;
  font-size: 22px;
  font-weight: 800;
}
.phone-contact span {
  text-align: center;
}
.phone-contact:hover {
  color: #7f7f7f;
}

.footer__phone-link:hover {
  color: #ccc;
}

@media (max-width: 500px) {
  .footer__phone-link {
    display: inline-block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .header_phone_h3 {
    font-size: 20px;
  }
}
@media (max-width: 350px) {
  .header_phone_h3 {
    font-size: 18px;
  }
}
@media (max-width: 320px) {
  .header_phone_h3 {
    font-size: 14px;
  }
}
