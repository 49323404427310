.karcher {
  max-width: 180px;
  height: 60px;
}

@media (max-width: 600px) {
  .karcher {
    max-width: 130px;
    height: 50px;
  }
}
@media (max-width: 370px) {
  .karcher {
    max-width: 100px;
    height: 40px;
  }
}
@media (max-width: 300px) {
  .karcher {
    max-width: 90px;
    height: 35px;
  }
}
