.mySwiper__gallery .swiper-button-prev {
  color: black;
}
.mySwiper__gallery .swiper-button-next {
  color: black;
}

.galarry-block_1,
.galarry-block_4,
.galarry-block_2,
.galarry-block_3,
.galarry-block_5 {
  padding: 30px;
  background-color: #ffffff;

  border-radius: 10px;
}

.block {
  padding: 25px;
  /* background-color: aliceblue; */
  text-align: center;
  border-radius: 20px;
  max-width: 40%;
  margin: 0 auto;
  position: relative;
  top: 10px;
  left: 0;
}
.special {
  padding: 30px;
  /* background-color: aliceblue; */
  font-size: 18px;
  border-radius: 10px;
  min-height: 130px;
  margin: 35px 25px;
}
.main-title-gallary {
  padding: 30px;
  text-align: center;
}
.main-title-gallary h2 {
  font-size: 22px;
  padding: 30px;
  text-align: center;
}
.main-title-gallary p {
  font-size: 18px;
}
.swiper-wrapper {
  background-color: #f4fbfd;
}
.main__second-services {
  background-color: #f4fbfd;
  padding: 30px;
}
@media (max-width: 580px) {
  .mySwiper__gallery .swiper-button-prev {
    color: black;
    padding-right: 10px;
  }
  .mySwiper__gallery .swiper-button-next {
    color: black;
    padding-left: 10px;
  }
}
@media (max-width: 600px) {
  .main-title-gallary {
    padding: 15px;
  }
  .main-title-gallary h2 {
    font-size: 18px;
  }
  .main-title-gallary p {
    font-size: 15px;
  }
}
@media (max-width: 400px) {
  .main-title-gallary {
    padding: 10px;
  }
  .main-title-gallary h2 {
    font-size: 14px;
  }
  .main-title-gallary p {
    font-size: 12px;
  }
}
