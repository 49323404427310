.lineup-img {
  font-size: 40px;
  color: white;
  position: fixed;
  bottom: 30px;
  right: 150px;
  border: 1px solid white;
  padding: 2px;
  border-radius: 15px;
  transition: 0.3s;
  cursor: pointer;
}
.lineup-img:hover {
  background-color: white;
  color: #000;
  transition: all 0.5s;
}
.lineup-img-none {
  display: none;
  /* font-size: 0px;
  color: white;
  position: fixed;
  bottom: 30px;
  right: 170px;
  border: none;
  padding: 2px;
  border-radius: 15px;
  transition: 0.5s;
  cursor: none;
  opacity: 0;
  transition: all 0.1ms; */
}
@media (max-width: 500px) {
  .lineup-img {
    font-size: 30px;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 100px;
    border: 1px solid white;
    padding: 2px;
    border-radius: 15px;
    transition: 0.3s;
    cursor: pointer;
  }
  .lineup-img:hover {
    background-color: white;
    color: #000;
    transition: all 0.5s;
  }
  .lineup-img-none {
    display: none;
    /* font-size: 0px;
    color: white;
    position: fixed;
    bottom: 30px;
    right: 115px;
    border: none;
    padding: 2px;
    border-radius: 15px;
    transition: 0.5s;
    cursor: none;
    opacity: 0;
    transition: all 0.1ms; */
  }
}
