.header__schedule {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 2px solid black; */
}
.header-schedule-item {
  padding: 5px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
}
.email__schedule {
  font-size: 18px;
  fill: #03a9f4;
  stroke: #000;
  margin-left: 2px;
}
