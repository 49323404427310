/* Whoops404 */
.error404 {
  margin-top: 50px;
}
.error404 p {
  font-size: 20px;
  text-align: center;
}
.nofindresurs {
  margin: 20px auto;
  text-align: center;
}
.nofindresurs span {
  font-size: 20px;
  font-weight: 400;
}
.linkpath {
  font-size: 24px;
  font-family: Georgia, 'Times New Roman', Times, serif;
  color: rgb(29, 109, 136);
}
.linkpath:hover {
  color: rgb(90, 149, 147);
}

@media (max-width: 600px) {
  .error404 p {
    font-size: 16px;
  }
  .nofindresurs {
    margin: 20px auto;
    text-align: center;
    font-size: 18px;
  }
  .nofindresurs span {
    font-size: 16px;
  }
  .linkpath {
    font-size: 18px;
  }
}
