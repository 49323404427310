.header__list-social-media {
  display: flex;
  align-items: center;
  gap: 15px;
}
.social_logo {
  fill: #000000;
  background-color: #03a9f4;
  font-size: 22px;
  padding: 2px 3px;
  transition: 0.3s;
  border-radius: 2px;
}
.social_logo:hover {
  background-color: #ffffff;
  transition: 0.3s;
}

@media (max-width: 560px) {
  .close-social {
    display: none;
  }
}
@media (max-width: 510px) {
  .header__social-link-item {
    /* width: 50px; */
    gap: 2px;
  }
}
@media (max-width: 360px) {
}
