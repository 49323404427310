.whatsappLink {
  scale: 0.5;
  position: fixed;
  bottom: 10px;
  right: 0px;
  opacity: 0.8;
  transition-duration: 0.7s;
  animation: blink-animation 1.6s infinite;
  z-index: 100;
}
.whatsappLink:hover {
  opacity: 1;
}
@media (max-width: 500px) {
  .whatsappLink {
    position: fixed;
    bottom: 0px;
    right: -10px;
    scale: 0.4;
  }
}
@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}
