.uslugi {
  background-color: #fff;
}
/* slider1 */
.wrapper__uslugi {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding-bottom: 50px;
  margin-top: 20px;
}
.wrapper__img-text {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 0 auto;
  width: 100%;
}
.wrapper__img-text img {
  border-radius: 10px;
}
.swiper-pagination {
  color: #ffffff;
}
/* .uslugi-list {
  max-height: 600px;
} */
.uslugi-list ul {
  width: 300px;
  list-style: none;
}
.uslugi-list span {
  display: inline-block;
  font-size: 22px;
  font-weight: 700;
  padding: 10px 0;
}
.uslugi-list ul li {
  width: 100%;
  font-size: 18px;
  margin: 20px auto 10px auto;
  display: inline-block;
}
.uslugi-list ul li:hover {
  color: #03a9f4;
}
.uslugi__line-under {
  width: 60%;
  height: 2px;
  background-color: rgb(22, 114, 218);
  opacity: 0.2;
  margin-left: auto;
}
.uslugi__text {
  background: #f4fbfd;
  padding: 15px 25px;
  border-top: 5px solid #03a9f4;
}
.uslugi__text h2 {
  margin: 20px 0;
}
.uslugi__text h3 {
  margin: 20px 0;
}
.uslugi__text ul {
  margin-left: 15px;
}
.uslugi__text ul li {
  margin: 15px 0;
}
@media (max-width: 1000px) {
  .uslugi-list ul {
    width: 250px;
    list-style: none;
  }
}
@media (max-width: 800px) {
  .wrapper__uslugi {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding-bottom: 50px;
  }
}
@media (max-width: 500px) {
  .uslugi__text p,
  .uslugi__text ul {
    font-size: 14px;
  }
}
@media (max-width: 330px) {
  .uslugi-list ul li {
    font-size: 14px;
  }
}
