.slider {
  position: relative;
  z-index: 2;
  /* background-image: url('../../img/sliders/banner3.jpg'); */
  background-image: url('../../img/sliders/slider_sofa.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  background-position: 0% 18%;
  width: 100vw;
}
.sliderCar {
  position: relative;
  z-index: 2;
  background-image: url('../../img/sliders/slider_car.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  width: 100vw;
}
.sliderKids {
  position: relative;
  z-index: 2;
  background-image: url('../../img/sliders/slider_kids.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  width: 100vw;
  padding-bottom: 0px;
}
.sliderMatress {
  position: relative;
  z-index: 2;
  background-image: url('../../img/sliders/matress.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  width: 100vw;
  padding-bottom: -20px;
}
.sliderRug {
  position: relative;
  z-index: 2;
  background-image: url('../../img/sliders/rug.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #000000;
  background-position: left 90%;
  width: 100vw;
  padding-bottom: 0px;
}

.backdrop-sliders {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
}
.slider__text {
  position: relative;
  padding-top: 50px;
  color: #ffffff;
  width: 85%;
  margin: 0 auto;
}
.slider__text-title {
  font-size: 35px;
  margin-top: 150px;
}
.slider__text-p {
  font-size: 25px;
  margin: 30px 0;
}
.slider__text-btn {
  border: none;
  padding: 1rem 1.5rem;
  border-radius: 5px;
  box-shadow: none;
  background-color: #03a9f4;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
  transition: background-color 0.3s ease;
  cursor: pointer;
  outline: none;
  margin: 30px 0;
}
.slider__text-btn:hover {
  background-color: #188dc3;
  transition: background-color 0.3s ease;
}
.slider__garantia {
  color: #ffffff;
  padding: 50px 0;
  margin: 20px auto 0px auto;
  text-align: center;
  font-style: italic;
  width: 100%;
  opacity: 0;
}
.autoplay-progress {
  position: absolute;
  right: 25px;
  top: 25px;
  z-index: 10;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: var(--swiper-theme-color);
}
.autoplay-progress svg {
  --progress: 0;
  position: absolute;
  left: 0;
  top: 0px;
  z-index: 10;
  width: 100%;
  height: 100%;
  stroke-width: 4px;
  stroke: var(--swiper-theme-color);
  fill: none;
  stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
  stroke-dasharray: 125.6;
  transform: rotate(-90deg);
}
.swiper-button-prev,
.swiper-button-next {
  color: #ffffff;
}
@media (max-width: 1000px) {
  .slider__text-title {
    font-size: 25px;
  }
  .slider__text-p {
    font-size: 20px;
    margin-bottom: 25px;
  }
  /* .extra {
    padding-bottom: 0;
  } */
}
@media (max-width: 700px) {
  .slider {
    position: relative;
    z-index: 2;
    background-image: url('../../img/sliders/slider_sofa.jpg');
    /* background-image: url('../../img/sliders/banner3.jpg'); */
    background-position: 0% 0%;
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: cover;
    background-color: #000000;
    width: 100vw;
  }
  .sliderCar {
    padding-bottom: 48px;
  }
  .sliderKids {
    padding-bottom: 20px;
  }
  .sliderRug {
    padding-bottom: 25px;
  }
  .slider__text-title {
    font-size: 20px;
    margin-top: 100px;
  }
  .slider__text-p {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .slider__text-btn {
    border: none;
    padding: 0.8rem 1.2rem;
    border-radius: 5px;
    box-shadow: none;
    background-color: #03a9f4;
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    transition: background-color 0.3s ease;
    cursor: pointer;
    outline: none;
    font-size: 12px;
  }
  .slider__garantia {
    color: #ffffff;
    padding: 10px 0 30px 0;
    margin: 20px auto 0px auto;
    text-align: center;
    font-style: italic;
    width: 100%;
    font-size: 14px;
  }
}
@media (max-width: 490px) {
  .sliderCar {
    padding-bottom: 0px;
  }
  .sliderKids {
    padding-bottom: 20px;
  }
}
@media (max-width: 450px) {
  .slider__text-title {
    font-size: 20px;
    margin-top: 50px;
  }
  .slider__text-p {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
@media (max-width: 350px) {
  .slider__text-title {
    font-size: 16px;
    margin-top: 50px;
  }
  .slider__text-p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  .slider__garantia {
    color: #ffffff;
    padding: 10px 0 30px 0;
    margin: 20px auto 0px auto;
    text-align: center;
    font-style: italic;
    width: 100%;
    font-size: 12px;
  }
}
