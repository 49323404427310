.logo {
  display: block;
  width: 150px;
}

@media (max-width: 480px) {
  .logo {
    width: 110px;
  }
}
@media (max-width: 380px) {
  .logo {
    width: 90px;
  }
}
