.main__articles {
  /* position: relative; */
  top: 0;
  left: 0;
  padding-top: 45px;
}
.main-title-article {
  margin: 10px auto 45px auto;
  padding: 50px;
  opacity: 0.9;
  border-radius: 20px;
  text-align: center;
}
.main-title-article p {
  font-size: 24px;
  font-weight: 500;
  color: black;
  font-family: Georgia, serif;
  margin-bottom: 20px;
}
.main-title-article h2 {
  margin: 0 auto;
}
@media (max-width: 500px) {
  .main__articles {
    padding-top: 15px;
  }
  .main-title-article {
    margin: 10px auto 5px auto;
    padding: 0 15px;
    opacity: 0.9;
    border-radius: 20px;
    text-align: center;
  }
  .main-title-article p {
    font-size: 18px;
    font-weight: 500;
    color: black;
    font-family: Georgia, serif;
    margin-bottom: 20px;
  }
  .main-title-article h2 {
    margin: 0 auto;
    font-size: 18px;
  }
}
@media (max-width: 350px) {
  .main__articles {
    padding-top: 15px;
  }
  .main-title-article {
    margin: 10px auto 5px auto;
    padding: 0 15px;
    opacity: 0.9;
    border-radius: 20px;
    text-align: center;
  }
  .main-title-article p {
    font-size: 14px;
    font-weight: 500;
    color: black;
    font-family: Georgia, serif;
    margin-bottom: 20px;
  }
  .main-title-article h2 {
    margin: 0 auto;
    font-size: 14px;
  }
}
