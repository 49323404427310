.uslugi__text p {
  margin-top: 15px;
}

.swiper-slide .curtens {
  display: block;

  width: 100%;
}
.mySwiperUslugi .swiper-button-prev,
.mySwiperUslugi .swiper-button-next {
  color: #03a9f4;
}

.mySwiperUslugi img {
  width: 100%;
  object-fit: fill;
}
