.backdrop-form {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 100;
}
.get-forma {
  position: fixed;
  left: 0;
  right: 0;
  margin: 0 auto;
}
