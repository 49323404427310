.header-top {
  background-color: #252525;
  color: #fff;
  padding: 10px 0;
}
.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1em;
}
.header-top__wrapper {
  display: flex;
  align-items: center;
}
.header-top__contacts {
  display: flex;
  gap: 10px;
}
.wrapper__social-lang {
  display: flex;
  align-items: flex-end;
  margin-left: auto;
  gap: 20px;
}
@media (max-width: 730px) {
  .header-top__contacts {
    display: flex;
    gap: 10px;
    flex-direction: column;
  }
}
