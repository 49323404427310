.header {
  background: #fff;
  margin-top: 0;
  box-shadow: 2px 2px 8px 2px #bdbdbd;
}
.container {
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 1em;
}
.header__logo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
.header__wrapper {
  display: flex;
  align-items: center;
}
.header__menu {
  margin-left: auto;
}
.backdropMenu {
  position: fixed;
  background-color: rgba(0, 0, 0, 0);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}
.hidden {
  display: none;
}
@media (max-width: 380px) {
  .header__logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
  }
}
