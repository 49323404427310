.email__p {
  font-weight: 500;
  font-size: 13px;
  line-height: 1.5;
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;
}
.email__p span:nth-child(1) {
  margin-left: 5px;
}
.email__link span:nth-child(3):hover {
  color: #03a9f4;
  text-decoration: underline;
}

.email__logo {
  font-size: 20px;
  fill: #03a9f4;
  stroke: #000;
  line-height: 1.2;
}
.email-contact {
  text-align: center;
  color: #000;
}
.email-contact span:nth-child(2):hover {
  color: #03a9f4;
}

@media (max-width: 372px) {
  .close-email {
    display: none;
  }
  .email__p {
    font-size: 12px;
  }
}
@media (max-width: 300px) {
  .email__p {
    font-size: 11px;
  }
}
