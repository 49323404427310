.logo {
  display: block;
  width: 150px;
  margin: 10px 0;
}
.logo img {
  border-radius: 20% 0px 20% 0px;
  /* border-radius: 50%; */
}
@media (max-width: 480px) {
  .logo {
    width: 110px;
  }
}
@media (max-width: 380px) {
  .logo {
    width: 90px;
  }
}
